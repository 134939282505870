import * as React from 'react';
import { useIntersectionObserver } from '~/lib/react/hooks/useIntersectionObserver';

export function useIsVisibleInViewport<T extends Element>(
  ref: React.RefObject<T>
) {
  const [isVisibleInViewPort, setIsVisibleInViewPort] = React.useState(false);

  useIntersectionObserver(
    ref,
    React.useCallback((entries) => {
      setIsVisibleInViewPort(
        entries.every(
          (entry) => entry.isIntersecting && entry.intersectionRatio >= 0.75
        )
      );
    }, [])
  );

  return isVisibleInViewPort;
}
