import * as React from 'react';

export function useIntersectionObserver<T extends Element>(
  elementRef: React.RefObject<T>,
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit = { threshold: [0, 0.7, 0.8, 0.9] }
) {
  const intersectionObserver = React.useRef<IntersectionObserver>();

  React.useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    const element = elementRef.current;

    intersectionObserver.current = observer;

    if (element) {
      intersectionObserver.current.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [callback, elementRef, options]);
}
