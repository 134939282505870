import * as React from 'react';
import type { NextPage } from 'next';
import cn from 'classnames';

import { Layout } from '~/components/Layout';
import { AddToSlackTermsDescriptor } from '~/components/AddToSlackLink';
import { LoginWithSlackLink } from '~/components/LoginWithSlackLink';
import { SlackLogo } from '~/components/SlackLogo';
import { Divider } from '~/components/Divider';
import {
  SlackMessage,
  SlackChannelLink,
  SlackButton,
  SlackInputBox,
} from '~/components/SlackMessage';
import { LinkButton } from '~/components/Button';
import { PricingListing } from '~/components/PricingListing';
import { useTypewriter } from '~/lib/react/hooks/useTypewriter';
import { useTimeout } from '~/lib/react/hooks/useTimeout';
import { useIsVisibleInViewport } from '~/lib/pages/homepage/hooks/useIsVisibleInViewport';

type CommandName = string;

// This is needed to correctly center the /tmp command when the animation changes
// If you add a new command below, make sure to check what the command center
// correction should be.
// This is due to the lack of finding a proper algorithm for calculating the
// correct value. For now hard coding is how we do it.
type CommandCenterCorrection = number;

const commands: [CommandName, CommandCenterCorrection][] = [
  ['marketing meeting', 0],
  ['project hawk', 85],
  ['workshop', 125],
  ['brainstorm session', 15],
  ['design meeting', 50],
  ['mobile login crash', 20],
  ['react proposal', 60],
  ['ddos attack', 100],
  ['integrate analytics', 15],
  ['redis outage', 90],
];

const Container: React.FunctionComponent<React.ComponentProps<'div'>> = (
  props
) => (
  <div {...props} className={`mx-auto max-w-7xl ${props.className || ''}`} />
);

const Section = React.forwardRef<HTMLElement, React.ComponentProps<'section'>>(
  function Section(props, ref) {
    return (
      <section
        ref={ref}
        {...props}
        className={`px-4 sm:px-6 lg:px-8 py-10 sm:py-12 md:py-16 xl:py-22 ${
          props.className || ''
        }`}
      />
    );
  }
);

const useScroller = (
  maxIndex: number,
  { speed = 1000, startIndex = 0 } = {}
) => {
  const [index, setIndex] = React.useState(startIndex);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % maxIndex);
    }, speed);

    return () => clearInterval(interval);
  }, [maxIndex, speed]);

  return index;
};

const HeroBanner = () => {
  const commandIndex = useScroller(commands.length, { speed: 2000 });
  const [, currentCenterCorrection] = commands[commandIndex];

  return (
    <Section>
      <Container className="text-center">
        <h1 className="flex flex-col sm:flex-row justify-center text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <div
            className="block sm:inline text-rose-600 flex-shrink-0 sm:pr-3 transition-transform sm:translate-x-custom"
            style={
              {
                ['--custom-translate-x']: `${currentCenterCorrection}px`,
              } as React.CSSProperties
            }
          >
            /tmp
          </div>
          <div className="relative w-full text-center sm:text-left sm:w-auto">
            <span className="invisible" aria-hidden>
              {commands[0][0]}
            </span>
            {commands.map(([command, centerCorrection], index) => {
              const isCurrentCommand = commandIndex === index;

              return (
                <span
                  className={cn(
                    'absolute left-0 right-0 transition-all ease-in-out sm:translate-x-custom',
                    {
                      'translate-y-0 opacity-100': isCurrentCommand,
                      '-translate-y-3 opacity-0': !isCurrentCommand,
                    }
                  )}
                  style={
                    {
                      ['--custom-translate-x']: `${centerCorrection}px`,
                    } as React.CSSProperties
                  }
                  key={command}
                >
                  {command}
                </span>
              );
            })}
          </div>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl">
          Create temporary channels with one simple command
        </p>
        <div className="mt-5 sm:mt-6 flex justify-center">
          <div>
            <LoginWithSlackLink passHref>
              <LinkButton className="group flex items-center justify-center">
                <SlackLogo className="mr-4" />
                Sign up for free
              </LinkButton>
            </LoginWithSlackLink>
          </div>
        </div>
        <div className="pt-4">
          <AddToSlackTermsDescriptor />
        </div>
      </Container>
    </Section>
  );
};

const slackMessages = [
  {
    id: 1,
    name: 'Boyd',
    time: '4:38 PM',
    avatar: '/avatars/boyd.png',
    messages: ['Oh noes! Our response time is slowing down 😱'],
  },
  {
    id: 2,
    name: 'Me',
    time: '4:38 PM',
    avatar: '/avatars/kate.png',
    messages: ['Interesting… Let’s investigate 🤔', "I'll make a channel"],
  },
  {
    id: 3,
    name: 'TmpChannel',
    isApp: true,
    time: '4:39 PM',
    avatar: '/avatars/tmp.png',
    messages: ['✅   Temporary Channel created: #tmp-slow-response'],
  },
];

function calculateMessageOffsets(
  containerHeight: number,
  firstMessageRef: React.RefObject<HTMLDivElement>,
  secondMessageRef: React.RefObject<HTMLDivElement>
) {
  const MESSAGE_HEIGHT = 20;

  const firstMessageOffset =
    containerHeight - (firstMessageRef.current?.offsetHeight || 0);
  const thirdMessageOffset =
    firstMessageOffset - (secondMessageRef.current?.offsetHeight || 0);

  // The second message contains also the third message.
  // So we calculate the third message first and then subtract one message.
  const secondMessageOffset = thirdMessageOffset + MESSAGE_HEIGHT;

  return {
    firstMessage: firstMessageOffset,
    secondMessage: secondMessageOffset,
    thirdMessage: thirdMessageOffset,
  };
}

const CollaborateSection = () => {
  const rootEl = React.useRef<HTMLElement>(null);
  const messageWrapperRef = React.useRef<HTMLDivElement>(null);
  const firstMessageRef = React.useRef<HTMLDivElement>(null);
  const secondMessageRef = React.useRef<HTMLDivElement>(null);
  const isTyping = React.useRef(false);
  const [messageBoxContainerHeight, setMessageBoxContainerHeight] =
    React.useState(189);
  const isVisibleInViewPort = useIsVisibleInViewport(rootEl);

  const messageOffsets = calculateMessageOffsets(
    messageBoxContainerHeight,
    firstMessageRef,
    secondMessageRef
  );

  const inputBoxTypeWriter = useTypewriter(
    '/tmp slow response',
    ({ reset }) => {
      setTimeout(() => {
        reset();
      }, 600);
    }
  );

  const inputBoxTypeWriterTimer = useTimeout(() => {
    isTyping.current = true;
    inputBoxTypeWriter.start();
  }, 3250);

  React.useEffect(() => {
    if (isVisibleInViewPort) {
      if (!isTyping.current) {
        inputBoxTypeWriterTimer.start();
      }
    } else {
      inputBoxTypeWriterTimer.reset();
      inputBoxTypeWriter.reset();
      isTyping.current = false;
    }
  }, [isVisibleInViewPort, inputBoxTypeWriterTimer, inputBoxTypeWriter]);

  React.useEffect(() => {
    if (messageWrapperRef.current) {
      setMessageBoxContainerHeight(messageWrapperRef.current.offsetHeight);
    }
  }, []);

  return (
    <Section ref={rootEl}>
      <Container className="flex flex-wrap md:flex-nowrap max-w-[750px]">
        <div className="w-full md:w-5/12 p-2">
          <h2
            className="text-sm text-rose-600 uppercase font-bold tracking-wide"
            aria-hidden
          >
            Collaborate
          </h2>
          <h1 className="text-3xl font-bold">Collaborate in an instant</h1>
          <p className="md:pt-2 text-gray-500 md:max-w-[250px]">
            Create single purpose channels with a single command with
            <br />
            <span className="text-rose-600">{'/tmp <your subject>'}</span>
          </p>
        </div>

        <div
          className={cn(
            'flex flex-col w-full md:w-7/12 border-2 border-gray-200 p-7 mt-4 md:-mt-3 rounded-2xl',
            isVisibleInViewPort
              ? 'hp-collaborate-animation-start'
              : 'hp-collaborate-animation-end'
          )}
        >
          <div
            className="overflow-hidden"
            style={{ height: `${messageBoxContainerHeight}px` }}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: `
              .hp-collaborate-message-container {
                --firstMessageOffset: ${messageOffsets.firstMessage}px;
                --secondMessageOffset: ${messageOffsets.secondMessage}px;
                --thirdMessageOffset: ${messageOffsets.thirdMessage}px;
              }
            `,
              }}
            />
            <div
              ref={messageWrapperRef}
              className="flex flex-col justify-end hp-collaborate-message-container"
            >
              <div ref={firstMessageRef}>
                <SlackMessage {...slackMessages[0]} />
              </div>
              <div
                ref={secondMessageRef}
                className={cn(
                  'pt-3',
                  isVisibleInViewPort ? 'opacity-100' : 'opacity-0'
                )}
              >
                <SlackMessage {...slackMessages[1]} />
              </div>
              <div
                className={cn(
                  'pt-3',
                  isVisibleInViewPort ? 'opacity-100' : 'opacity-0'
                )}
              >
                <SlackMessage {...slackMessages[2]} />
              </div>
            </div>
          </div>

          <SlackInputBox className="-mx-3 mt-4 -mb-3">
            {inputBoxTypeWriter.getState()}
          </SlackInputBox>
        </div>
      </Container>
    </Section>
  );
};

const channels = [
  { name: '#tmp-slow-response', created: '2 hours ago' },
  { name: '#tmp-marketing-meeting', created: '3 months ago' },
  { name: '#tmp-mobile-login-crash', created: '4 months ago' },
];

const ManageSection = () => {
  const rootEl = React.useRef<HTMLElement>(null);
  const [animationState, setAnimationState] = React.useState('idle');
  const isVisibleInViewPort = useIsVisibleInViewport(rootEl);

  const inputBoxTypeWriter = useTypewriter('/tmp list', ({ reset }) => {
    setTimeout(() => {
      setAnimationState('post');
      reset();
    }, 800);
  });

  React.useEffect(() => {
    if (isVisibleInViewPort) {
      if (animationState === 'idle') {
        inputBoxTypeWriter.start();
      }
    } else {
      inputBoxTypeWriter.reset();
      setAnimationState('idle');
    }
  }, [isVisibleInViewPort, inputBoxTypeWriter, animationState]);

  return (
    <Section ref={rootEl}>
      <Container className="flex flex-wrap flex-row-reverse md:flex-nowrap max-w-[750px]">
        <div className="w-full md:w-5/12 p-2 md:pl-12">
          <h2
            className="text-sm text-rose-600 uppercase font-bold tracking-wide"
            aria-hidden
          >
            Manage
          </h2>
          <h1 className="text-3xl font-bold">
            Full control over your channels
          </h1>
          <p className="md:pt-2 text-gray-500 md:max-w-[250px]">
            With <span className="text-rose-600">/tmp list</span> you can easily
            keep track of your created channels and archive them whenever you
            see fit
          </p>
        </div>

        <div className="w-full md:w-7/12 border-2 border-gray-200 p-7 mt-4 md:-mt-3 rounded-2xl">
          <div
            className={cn(
              'transition-all ease-in-out duration-500',
              animationState === 'post' ? '' : 'translate-y-3 opacity-0'
            )}
          >
            <SlackMessage
              name="TmpChannel"
              isApp
              time="4:39 PM"
              avatar="/avatars/tmp.png"
            >
              <p className="text-[15px]">
                You have a total of:{' '}
                <strong className="font-semibold">3 temporary channels</strong>.
              </p>

              {channels.map((channel) => {
                return (
                  <React.Fragment key={channel.name}>
                    <hr className="my-2" />

                    <div className="flex justify-between py-1">
                      <p className="w-[calc(55%-16px)] text-[15px]">
                        <strong className="block font-semibold">
                          Channel:
                        </strong>
                        <SlackChannelLink>{channel.name}</SlackChannelLink>
                      </p>
                      <p className="w-[calc(35%-16px)] text-[15px]">
                        <strong className="block font-semibold">
                          Created:
                        </strong>
                        {channel.created}
                      </p>
                      <p className="text-[15px]">
                        <SlackButton>Archive</SlackButton>
                      </p>
                    </div>
                  </React.Fragment>
                );
              })}
            </SlackMessage>
          </div>

          <SlackInputBox className="-mx-3 mt-4 -mb-3">
            {inputBoxTypeWriter.getState()}
          </SlackInputBox>
        </div>
      </Container>
    </Section>
  );
};

const CleanupSection = () => {
  const rootEl = React.useRef<HTMLElement>(null);
  const [animationState, setAnimationState] = React.useState('idle');
  const isVisibleInViewPort = useIsVisibleInViewport(rootEl);

  const inputBoxTypeWriter = useTypewriter('/tmp clean', ({ reset }) => {
    setTimeout(() => {
      setAnimationState('post');
      reset();
    }, 800);
  });

  React.useEffect(() => {
    if (isVisibleInViewPort) {
      if (animationState === 'idle') {
        inputBoxTypeWriter.start();
      }
    } else {
      inputBoxTypeWriter.reset();
      setAnimationState('idle');
    }
  }, [isVisibleInViewPort, inputBoxTypeWriter, animationState]);

  return (
    <Section ref={rootEl}>
      <Container className="flex flex-wrap items-center md:flex-nowrap max-w-[750px]">
        <div className="w-full md:w-5/12">
          <div className="p-2">
            <h2
              className="text-sm text-rose-600 uppercase font-bold tracking-wide"
              aria-hidden
            >
              Cleanup
            </h2>
            <h1 className="text-3xl font-bold">
              Automatically archive inactive channels
            </h1>
            <p className="md:pt-2 text-gray-500 md:max-w-[250px]">
              Automatically find your inactive temporary channels and archive
              them with <span className="text-rose-600">/tmp clean</span>
            </p>
          </div>
        </div>

        <div className="w-full md:w-7/12 border-2 border-gray-200 p-7 mt-4 md:-mt-3 rounded-2xl">
          <div
            className={cn(
              'transition-all ease-in-out duration-500',
              animationState === 'post' ? '' : 'translate-y-3 opacity-0'
            )}
          >
            <SlackMessage
              name="TmpChannel"
              isApp
              time="4:39 PM"
              avatar="/avatars/tmp.png"
            >
              <p className="text-[15px]">
                Which clean up strategy do you want to use?
              </p>

              <p className="pt-2">
                <SlackButton>🧹 Archive inactive (30 days)</SlackButton>
              </p>
            </SlackMessage>
          </div>

          <SlackInputBox className="-mx-3 mt-6 -mb-3">
            {inputBoxTypeWriter.getState()}
          </SlackInputBox>
        </div>
      </Container>
    </Section>
  );
};

const PricingSection = () => (
  <Section className="bg-slate-200">
    <Container>
      <div className="text-center md:pb-6">
        <h1 className="text-3xl font-bold">Pricing</h1>
        <p className="text-slate-700">Start simple, grow as you need</p>
      </div>

      <PricingListing
        planStarterCTA={
          <LoginWithSlackLink passHref>
            <LinkButton
              variant="secondaryOutline"
              className="group flex justify-center items-center"
            >
              <SlackLogo className="mr-2" />
              Sign up for free
            </LinkButton>
          </LoginWithSlackLink>
        }
        planPremiumCTA={
          <LoginWithSlackLink passHref>
            <LinkButton
              variant="secondaryOutline"
              className="group flex justify-center items-center"
            >
              <SlackLogo className="mr-2" />
              Sign up for free
            </LinkButton>
          </LoginWithSlackLink>
        }
      />

      <div className="pt-4">
        <AddToSlackTermsDescriptor />
      </div>
    </Container>
  </Section>
);

const Home: NextPage = () => {
  return (
    <Layout>
      <main>
        <HeroBanner />
        <Divider />
        <CollaborateSection />
        <Divider />
        <ManageSection />
        <Divider />
        <CleanupSection />
        <PricingSection />
      </main>
    </Layout>
  );
};

export default Home;
