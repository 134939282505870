import * as React from 'react';

export function useTimeout(func: () => void, delay: number) {
  const ref = React.useRef<ReturnType<typeof setTimeout>>();

  const start = React.useCallback(() => {
    if (ref.current) clearTimeout(ref.current);

    ref.current = setTimeout(func, delay);

    return () => {
      if (ref.current) clearTimeout(ref.current);
    };
  }, [func, delay]);

  const reset = React.useCallback(() => {
    if (ref.current) clearTimeout(ref.current);
  }, []);

  return React.useMemo(() => ({ start, reset }), [start, reset]);
}
