import Link from 'next/link';
import * as React from 'react';

export const AddToSlackLink: React.FC<
  Omit<React.ComponentProps<typeof Link>, 'href'>
> = (props) => <Link {...props} href="/install/app" />;

export const AddToSlackTermsDescriptor = () => (
  <div className="text-center">
    <p className="text-sm text-gray-800">
      14 day free trial • No credit card required to sign up
    </p>
    <p className="text-xs text-gray-600">
      By using TmpChannel you accept{' '}
      <Link href="/terms">
        <a className="underline">the terms</a>
      </Link>
      .
    </p>
  </div>
);
