import * as React from 'react';
import cn from 'classnames';
import { BadgeCheckIcon } from '@heroicons/react/outline';
import { Emoji } from '~/components/Emoji';

const PricingListingContainer: React.FunctionComponent<
  React.ComponentProps<'div'>
> = (props) => (
  <div
    {...props}
    className={`drop-shadow hover:drop-shadow-xl transition text-black bg-white mt-5 rounded-3xl p-5 ${
      props.className ?? ''
    }`}
  />
);

const PricingListingTitle: React.FunctionComponent<
  React.ComponentProps<'div'>
> = (props) => (
  <div {...props} className={`text-xl font-bold ${props.className ?? ''}`} />
);

const PricingListingCrossedOutPrice: React.FC<React.ComponentProps<'span'>> = (
  props
) => (
  <span
    {...props}
    className={cn(
      'relative before:block before:absolute before:left-[-5%] before:w-[110%] before:top-1/2 before:skew-y-[-12deg] before:border-rose-600 before:border-b-2',
      props.className
    )}
  />
);

const PricingListingPrice: React.FunctionComponent<
  React.ComponentProps<'div'>
> = (props) => (
  <div {...props} className={`text-lg ${props.className ?? ''}`} />
);

const PricingListBadge: React.FunctionComponent<React.ComponentProps<'div'>> = (
  props
) => (
  <div
    {...props}
    className={cn(
      'rounded-full bg-green-300 text-sm text-center px-3 py-1 font-bold text-green-900',
      props.className
    )}
  />
);

const LimitedDealBadge = () => (
  <div className="flex justify-center items-center rounded-full bg-yellow-100 text-sm text-center pl-2 pr-3 py-1 font-bold text-yellow-800 leading-none">
    <Emoji src="emojis/rocket.png" fallback="🚀" />{' '}
    <span className="ml-1">Limited time deal</span>
  </div>
);

interface Props {
  currentPlan?: string | null;
  planStarterCTA: React.ReactNode;
  planPremiumCTA: React.ReactNode;
}

export const PricingListing: React.FC<Props> = ({
  currentPlan,
  planStarterCTA,
  planPremiumCTA,
}) => (
  <div className="m-auto sm:max-w-2xl sm:flex sm:px-3">
    <PricingListingContainer className="sm:flex-[1] sm:mr-4 flex flex-col sm:min-h-[300px]">
      <div className="flex flex-col flex-grow justify-between">
        <div>
          <div className="flex flex-wrap sm:flex-nowrap items-center justify-between pb-4">
            <PricingListingTitle>Starter</PricingListingTitle>
            {currentPlan === 'starter' ? (
              <PricingListBadge>Current</PricingListBadge>
            ) : (
              <LimitedDealBadge />
            )}
          </div>

          <div>
            <PricingListingPrice className="text-sm">
              <PricingListingCrossedOutPrice
                className="text-gray-700"
                aria-label="From 1 dollar 99 a month"
              >
                $1,99 /mo
              </PricingListingCrossedOutPrice>
            </PricingListingPrice>
            <PricingListingPrice className="text-green-600">
              <span className="text-4xl font-bold">$0,99</span>
              <span
                className="text-gray-600"
                aria-label="per month"
                title="per month"
              >
                /mo
              </span>
            </PricingListingPrice>
          </div>

          <ul className="py-3">
            <li className="pt-1">
              Up to <strong>50 workspace users</strong>
            </li>
            <li className="pt-1">Email support</li>
            <li className="pt-1">Cancel anytime</li>
          </ul>
        </div>

        <div className="pt-4 md:flex md:justify-center">{planStarterCTA}</div>
      </div>
    </PricingListingContainer>

    <PricingListingContainer className="sm:flex-[1] sm:ml-4 flex flex-col sm:min-h-[300px]">
      <div className="flex flex-col flex-grow justify-between">
        <div>
          <div className="flex flex-wrap md:flex-nowrap justify-between pb-4">
            <PricingListingTitle>Premium</PricingListingTitle>
            {currentPlan === 'premium' ? (
              <PricingListBadge>Current</PricingListBadge>
            ) : (
              <LimitedDealBadge />
            )}
          </div>

          <div>
            <PricingListingPrice className="text-sm">
              <PricingListingCrossedOutPrice
                className="text-gray-700"
                aria-label="From 4 dollars 99 a month"
              >
                $4,99 /mo
              </PricingListingCrossedOutPrice>
            </PricingListingPrice>
            <PricingListingPrice className="text-green-600">
              <span className="text-4xl font-bold">$0,99</span>
              <span
                className="text-gray-600"
                aria-label="per month"
                title="per month"
              >
                /mo
              </span>
            </PricingListingPrice>
          </div>

          <ul className="py-3">
            <li className="flex items-center pt-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-green-600"
              />
              <span className="pl-2">Unlimited users</span>
            </li>
            <li className="flex items-center pt-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-green-600"
              />
              <span className="pl-2">Email support</span>
            </li>
            <li className="flex items-center pt-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-green-600"
              />
              <span className="pl-2">Cancel anytime</span>
            </li>
          </ul>
        </div>

        <div className="pt-4 md:flex md:justify-center">{planPremiumCTA}</div>
      </div>
    </PricingListingContainer>
  </div>
);
