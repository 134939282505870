import * as React from 'react';
import cn from 'classnames';

const variants = {
  primaryOutline:
    'border-rose-600 text-rose-600 bg-white hover:bg-rose-600 hover:text-white',
  primary:
    'border-rose-600 text-white bg-rose-600 hover:border-rose-800 hover:bg-rose-800',

  secondaryOutline:
    'border-gray-400 text-gray-700 bg-white hover:border-gray-500 hover:bg-gray-500 hover:text-white',

  saveOutline:
    'border-green-400 text-green-700 bg-white hover:border-green-600 hover:bg-green-600 hover:text-white',
};

const sizes = {
  small: 'px-4 py-2 md:py-2 md:px-6',
  large: 'px-8 py-3 md:py-3 md:px-10',
};

type Props = {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
};

export const LinkButton = React.forwardRef<
  HTMLAnchorElement,
  Props & React.ComponentProps<'a'>
>(function LinkButton(
  { variant = 'primaryOutline', size = 'large', className, ...restProps },
  ref
) {
  const variantStyles = variants[variant];
  const sizeStyles = sizes[size];

  return (
    <a
      ref={ref}
      {...restProps}
      className={cn(
        'border-2 text-base font-bold text-center rounded-full',
        variantStyles,
        sizeStyles,
        className ?? ''
      )}
    />
  );
});

export const Button: React.FC<Props & React.ComponentProps<'button'>> = ({
  variant = 'primaryOutline',
  size = 'large',
  className,
  ...props
}) => {
  const variantStyles = variants[variant];
  const sizeStyles = sizes[size];

  return (
    <button
      {...props}
      className={cn(
        'border-2 text-base font-bold text-center rounded-full',
        variantStyles,
        sizeStyles,
        className ?? ''
      )}
    />
  );
};
