import * as React from 'react';
import cn from 'classnames';

const slackFontFamilyStyles = {
  fontFamily:
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

const Avatar: React.VoidFunctionComponent<{ src: string }> = ({ src }) => (
  <div
    className="w-10 h-10 bg-slate-400 rounded-md bg-cover bg-center"
    style={{ backgroundImage: `url(${src})` }}
  />
);

type MessageProps = {
  isApp?: boolean;
  name: string;
  time: string;
  avatar: string;
  messages?: string[];
  children?: React.ReactNode;
};

export const SlackChannelLink: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <span
    className={cn(
      'text-[#1465a3] bg-[#e2eff4] hover:text-[#11508f] hover:bg-[#cce5f0] hover:cursor-pointer px-1 py-[1px] rounded-sm',
      className
    )}
  >
    {children}
  </span>
);

export const SlackButton: React.FC<React.ComponentProps<'button'>> = (
  props
) => (
  <button
    type="button"
    className="block text-[13px] min-w-[56px] text-[#e01e5a] border border-[#e01e5a4d] px-2 pb-[1px] h-[28px] font-semibold bg-white rounded cursor-pointer appearance-none shadow-none hover:bg-[#f8f8f8] hover:shadow-[0_1px_3px_0_rgba(0,0,0,0.08)] leading-3"
    {...props}
  />
);

export const SlackMessage: React.VoidFunctionComponent<MessageProps> = ({
  isApp = false,
  avatar,
  name,
  time,
  messages,
  children,
}) => {
  return (
    <div
      className="flex text-[15px] leading-snug"
      style={slackFontFamilyStyles}
    >
      <div className="flex-shrink-0 mr-2">
        <Avatar src={avatar} />
      </div>

      <div className="flex-grow">
        <div>
          <span className="font-bold">{name}</span>
          {isApp ? (
            <>
              {'\u00A0'}
              <span className="bg-[#dadada] text-[#646464] rounded-sm text-[10px] py-[1px] px-[3px] align-middle">
                APP
              </span>
            </>
          ) : null}
          {'\u00A0'}
          {'\u00A0'}
          <span className="text-[#616061] text-[12px]">{time}</span>
        </div>

        {messages
          ? messages.map((message) => {
              const shouldSplit = message.includes('#tmp');

              return (
                <p key={message} className="text-[15px]">
                  {shouldSplit
                    ? message.split(' ').map((word, index) => {
                        const isChannel = word.startsWith('#tmp');

                        if (isChannel) {
                          return (
                            <span key={index}>
                              <SlackChannelLink className="whitespace-nowrap">
                                {word}
                              </SlackChannelLink>{' '}
                            </span>
                          );
                        }

                        return <span key={index}>{word} </span>;
                      })
                    : message}
                </p>
              );
            })
          : null}
        {children ? children : null}
      </div>
    </div>
  );
};

export const SlackInputBox: React.FC<React.ComponentProps<'div'>> = ({
  className,
  ...props
}) => (
  <div
    className={cn(
      'rounded-md border border-gray-300 py-1 px-3 h-[34px]',
      className
    )}
    style={slackFontFamilyStyles}
    {...props}
  />
);
