import * as React from 'react';

function typeWriter(
  text: string,
  speed: number,
  setText: (text: string) => void,
  onFinish: () => void,
  i = 0
) {
  if (i >= text.length) {
    onFinish();
    return;
  }

  setText(text.substring(0, i + 1));

  // wait for a while and call this function again for next character
  setTimeout(function () {
    typeWriter(text, speed, setText, onFinish, i + 1);
  }, speed);
}

interface Typewriter {
  getState(): string;
  start(): void;
  reset(): void;
  setCurrentText: React.Dispatch<React.SetStateAction<string>>;
}

export function useTypewriter(
  finalText: string,
  onFinish: (actions: Typewriter) => void = () => {},
  speed = 50
) {
  const isStarted = React.useRef(false);
  const [currentText, setCurrentText] = React.useState('');

  const getState = React.useCallback(() => {
    return currentText;
  }, [currentText]);

  const reset = React.useCallback(() => {
    setCurrentText('');
  }, []);

  const start = React.useCallback(() => {
    if (isStarted.current) return;

    isStarted.current = true;

    typeWriter(finalText, speed, setCurrentText, () => {
      isStarted.current = false;
      onFinish({ getState, setCurrentText, start, reset });
    });
  }, [finalText, speed, getState, reset, onFinish]);

  return React.useMemo(
    () => ({ getState, setCurrentText, start, reset }),
    [getState, setCurrentText, start, reset]
  );
}
